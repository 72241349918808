<template>
  <div class="world-country-theme-list">
    <div v-if="dataReady">
      <div v-if="Object.keys(list).length > 0">
        <v-card
          v-for="(item, index) in list"
          class="mx-auto d-flex flex-column mb-1"
          max-width="600"
          @click="toVote(item)"
          :key="index"
          :disabled="!item.enabled"
        >
          <v-card-title class="justify-center pb-1"
            ><h5 style="word-break: normal;text-align:center">{{ item.name }}</h5></v-card-title
          >
          <v-card-text class="text-center">{{ item.description }}</v-card-text>
        </v-card>
      </div>
      <div v-else>
        <v-card class="mx-auto d-flex flex-column mb-1" max-width="600">
          <v-card-title class="justify-center"
            ><h4>Il n'y a pas de liste</h4></v-card-title
          >
        </v-card>
      </div>
    </div>
    <infinite-loading @infinite="infiniteHandler">
      <span slot="no-more"></span>
      <span slot="no-results"></span>
    </infinite-loading>
  </div>
</template>

<script>
import InfiniteLoading from "vue-infinite-loading";
import Vuex from "vuex";

export default {
  components: {
    InfiniteLoading,
  },
  name: "hot-list",
  data: () => ({
    dataReady: false,
  }),
  methods: {
    async infiniteHandler($state) {
      await this.$store.dispatch("list/getHotList", {
        loading: $state,
        typeList: "hot",
      });
      this.dataReady = true;
    },
    async toVote(item) {
      this.$router.push({
        name: item.route.name,
        params: item.route.params,
        replace: true,
      });
    },
  },
  computed: {
    // ...Vuex.mapState("list", ["worldCountryName"]),
    ...Vuex.mapState({
      list(state) {
        return state.list.hotList;
      },
    }),
  },
//   mounted: function () {
//     // this.$store.getters["list/breadcrumb"];
//     // this.$store.commit("vote/setQueryLimit", 20);
//   },
};
</script>
